import Vue from 'vue'
import VueRouter from 'vue-router'

import { getToken, removeToken} from "@/utils/auth";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('../components/common/Home.vue'),
    meta: { title: '' },
    children: [
      {
        path: '/warehouse/wardeninfo',
        name: 'warehoursewardeninfo',
        component: () => import('../views/logistics/warehoursewarden.vue'),
        meta: { title: '舍监仓库' }
      },
      {
        path: '/warehouse/hydropowerinfo',
        name: 'warehoursehydropowerinfo',
        component: () => import('../views/logistics/warehoursehydropower'),
        meta: { title: '水电仓库' }
      },
      {
        path: '/warehouse/syntheticalinfo',
        name: 'warehoursesyntheticalinfo',
        component: () => import('../views/logistics/warehoursesynthetical'),
        meta: { title: '综合仓库' }
      },
      {
        path: '/warehouse/canteen',
        name: 'warehoursecanteen',
        component: () => import('../views/logistics/warehoursecanteen'),
        meta: { title: '食堂仓库' }
      },
      {
        path: '/person',
        name: 'person',
        component: () => import('../views/person/index.vue'),
        meta: { title: '人员管理' }
      },
      {
        path: '/family',
        name: 'family',
        component: () => import('../views/family/family.vue'),
        meta: { title: '家属管理' }
      },
      {
        path: '/orderFood/lunch',
        name: 'lunch',
        component: () => import('../views/orderFood/lunch.vue'),
        meta: { title: '午餐' }
      },
      {
        path: '/orderFood/dinner',
        name: 'dinner',
        component: () => import('../views/orderFood/dinner.vue'),
        meta: { title: '晚餐' }
      },
      {
        path: '/orderFood/tomorrow',
        name: 'tomorrow',
        component: () => import('../views/orderFood/tomorrow.vue'),
        meta: { title: '明日备餐' }
      },
      {
        path: '/orderlist',
        name: 'orderlist',
        component: () => import('../views/orderList/index.vue'),
        meta: { title: '订单管理订单审核' }
      },
      {
        path: '/orderFood/delivermeal',
        name: 'delivermeal',
        component: () => import('../views/orderFood/delivermeal.vue'),
        meta: { title: '送饭明细汇总表' }
      },
      {
        path: '/teamGroup',
        name: 'teamgroup',
        component: () => import('../views/teamGroup/index.vue'),
        meta: { title: '班组配置' }
      },
      {
          path: '/package/list',
          name: 'packagelist',
          component: () => import('../views/package/index.vue'),
          meta: { title: '套餐管理' }
      },
      // {
      //     path: '/package/add',
      //     name: 'packageadd',
      //     component: () => import('../views/package/components/already.vue'),
      //     meta: { title: '套餐管理上架' }
      // },
      {
        path: '/external/writeoff',
        name: 'writeoff',
        component: () => import('../views/external/write-off.vue'),
        meta: { title: '外部人员用餐核销' }
      },
      {
        path: '/staff/check',
        name: 'staffcheck',
        component: () => import('../views/staff/check.vue'),
        meta: { title: '员工用餐核销' }
      },
      {
        path: '/mealdestory',
        name: 'mealdestory',
        component: () => import('../views/staff/mealdestory.vue'),
        meta: { title: '用餐核销' }
      },
      {
        path: '/staff/history',
        name: 'staffhistory',
        component: () => import('../views/staff/history.vue'),
        meta: { title: '员工用餐核销历史' }
      },
      {
        path: '/external/delivery',
        name: 'delivery',
        component: () => import('../views/external/delivery.vue'),
        meta: { title: '外部人员用餐送餐填报' }
      },
      {
        path: '/external/list',
        name: 'externallist',
        component: () => import('../views/external/list.vue'),
        meta: { title: '外部人员用餐列表' }
      },
      {
        path: '/external/report',
        name: 'externalreport',
        meta: { title: '免费餐明细表', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂月报/月度免费餐合计.cpt&ref_t=design&op=view' }
      },
      {
        path: '/questionnaire/list',
        name: 'questionnairelist',
        component: () => import('../views/question/nairelist.vue'),
        meta: { title: '满意度问卷管理' }
      },
      {
          path: '/question/list',
          name: 'questionlist',
          component: () => import('../views/question/list.vue'),
          meta: { title: '满意度问卷调查列表' }
        },
        {
          path: '/question/record',
          name: 'questionrecord',
          component: () => import('../views/question/record.vue'),
          meta: { title: '满意度问卷调查记录明细' }
        },
        {
          path: '/question/dish',
          name: 'dish',
          component: () => import('../views/question/dish.vue'),
          meta: { title: '菜品调查' }
        },
        {
          path: '/room/list',
          name: 'roomlist',
          component: () => import('../views/room/index.vue'),
          meta: { title: '房间管理列表' }
        },
        {
          path: '/room/empty',
          name: 'roomempty',
          component: () => import('../views/room/emptyroom.vue'),
          meta: { title: '空房列表' }
        },
        {
          path: '/stay/checkin',
          name: 'staycheckin',
          component: () => import('../views/stay/checkIn.vue'),
          meta: { title: '入住' }
        },
        {
          path: '/stay/already',
          name: 'staycheckin',
          component: () => import('../views/stay/already.vue'),
          meta: { title: '已入住人员' }
        },
        {
          path: '/stay/othercheckin',
          name: 'othercheckin',
          component: () => import('../views/stay/otheralready.vue'),
          meta: { title: '预入职人员' }
        },
        {
          path: '/stay/fkcheckin',
          name: 'othercheckin',
          component: () => import('../views/stay/fkalready.vue'),
          meta: { title: '访客' }
        },
        {
          path: '/stay/retreat',
          name: 'stayretreat',
          component: () => import('../views/stay/retreat.vue'),
          meta: { title: '退宿' }
        },
        {
          path: '/stay/change',
          name: 'staychange',
          component: () => import('../views/stay/change.vue'),
          meta: { title: '换宿' }
        },
        {
          path: '/stay/leave',
          name: 'stayleave',
          component: () => import('../views/stay/leave.vue'),
          meta: { title: '离宿' }
        },
        {
          path: '/cost/water',
          name: 'water',
          component: () => import('../views/cost/water.vue'),
          meta: { 
            title: '水费',
            noCache: true
        }
        },
        {
          path: '/cost/electric',
          name: 'electric',
          component: () => import('../views/cost/electric.vue'),
          meta: { title: '电费' }
        },
        {
          path: '/cost/stay',
          name: 'stay',
          component: () => import('../views/cost/stay.vue'),
          meta: { title: '住宿费用' }
        },
        {
          path: '/cost/ocrelec',
          name: 'ocrelec',
          component: () => import('../views/cost/ocrelec.vue'),
          meta: { title: '电费上传记录' }
        },
        {
          path: '/cost/ocrwater',
          name: 'ocrwater',
          component: () => import('../views/cost/ocrwater.vue'),
          meta: { title: '水费上传记录' }
        },
        {
          path: '/cost/ocrmodifywater',
          name: 'ocrmodifywater',
          meta: { title: '水表修改记录查询', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/水电费/水表度数修改记录表.cpt&ref_t=design&op=view' }
        },
        {
          path: '/cost/ocrmodifyelectric',
          name: 'ocrmodifyelectric',
          meta: { title: '电表修改记录查询', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/水电费/电表度数修改记录表.cpt&ref_t=design&op=view' }
        },
        {
          path: '/water/meterreading',
          name: 'watermeterreading',
          meta: { title: '水表未抄查询', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/水电费/OCR抄表情况-水表.cpt&ref_t=design&op=view' }
        },
        {
          path: '/elec/meterreading',
          name: 'elecmeterreading',
          meta: { title: '电表未抄查询', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/水电费/OCR抄表情况-电表.cpt&ref_t=design&op=view' }
        },
        {
          path: '/room/password',
          name: 'roompassword',
          meta: { title: '房间密码表', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/房间报表/房间密码表.cpt&ref_t=design&op=write' }
        },
        {
          path: '/property/list',
          name: 'property',
          component: () => import('../views/property/index.vue'),
          meta: { title: '资产管理' }
        },
        {
          path: '/cost/other',
          name: 'other',
          component: () => import('../views/cost/other.vue'),
          meta: { title: '其他费用' }
        },
        {
          path: '/repair',
          name: 'logisticsrepair',
          component: () => import('../views/logistics/repair.vue'),
          meta: { title: '维修' }
        },
        // {
        //   path: '/warehouse',
        //   name: 'logisticswarehouse',
        //   component: () => import('../views/logistics/warehouse.vue'),
        //   meta: { title: '仓库' }
        // },
        {
          path: '/hygiene/repair',
          name: 'hygienerepair',
          component: () => import('../views/hygiene/index.vue'),
          meta: { title: '卫生' }
        },
        {
          path: '/integral/repair',
          name: 'integralrepair',
          component: () => import('../views/integral/integral.vue'),
          meta: { title: '评分' }
        },
        {
          path: '/bottledwater',
          name: 'bottledwater',
          component: () => import('../views/bottledWater/index.vue'),
          meta: { title: '桶装水' }
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          // component: () => import('../views/orderFood/dinner.vue'),
          // meta: { title: '首页', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/form?viewlet=th%252F%25E6%2595%25B0%25E6%258D%25AE%25E5%2588%2586%25E6%259E%2590%252F%25E7%25BB%258F%25E8%2590%25A5%25E9%25A9%25BE%25E9%25A9%25B6%25E8%2588%25B1.frm&ref_t=design&ref_c=61ce99bd-4526-40bb-8cd3-ee1105ed5cf0' }
          meta: { title: '首页', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/form?viewlet=th/数据分析/数字化生活区.frm&ref_t=design&ref_c=61ce99bd-4526-40bb-8cd3-ee1105ed5cf0' }
        },
        {
          path: '/report/rice',
          name: 'rice',
          component: () => import('../views/reports/rice.vue'),
          meta: { title: '米面油煤气报表' }
        },
        {
          path: '/report/room',
          name: 'room',
          component: () => import('../views/reports/room.vue'),
          meta: { title: '房间报表' }
        },
        {
          path: '/report/stay',
          name: 'room',
          component: () => import('../views/reports/stay.vue'),
          meta: { title: '住宿费' }
        },
        {
          path: '/report/warerele',
          name: 'room',
          component: () => import('../views/reports/waterele.vue'),
          meta: { title: '水电费' }
        },
        {
          path: '/report/check',
          name: 'room',
          component: () => import('../views/reports/check.vue'),
          meta: { title: '卫生检查报表' }
        },
        {
          path: '/report/score',
          name: 'room',
          component: () => import('../views/reports/score.vue'),
          meta: { title: '积分管理报表' }
        },
        {
          path: '/report/water',
          name: 'room',
          component: () => import('../views/reports/water.vue'),
          meta: { title: '桶装水报表' }
        },
        {
          path: '/report/warehouse',
          name: 'room',
          component: () => import('../views/reports/warehouse.vue'),
          meta: { title: '仓库报表' }
        },
        {
          path: '/dataRecon/summary',
          name: 'summary',
          // component: () => import('../views/orderFood/dinner.vue'),
          meta: { title: '消费记录汇总', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/消费记录汇总表.cpt&op=write' }
        },
        {
          path: '/dataRecon/detail',
          name: 'detail',
          meta: { title: '消费记录明细', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/消费记录明细表.cpt&op=write' }
        },
        {
          path: '/dataRecon/refund',
          name: 'refund',
          meta: { title: '退款明细记录', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/退款记录明细表.cpt&op=write' }
        },
        {
          path: '/dataRecon/catering',
          name: 'catering',
          meta: { title: '餐别汇总表', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/餐别汇总表.cpt&op=write' }
        },
        {
          path: '/dataRecon/group',
          name: 'group',
          meta: { title: '各组每日就餐汇总', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/各组每日就餐汇总表.cpt&op=write' }
        },
        {
          path: '/procure/record',
          name: 'procurerecord',
          meta: { title: '采购录入', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/采购录入.cpt&op=write' }
        },
        {
          path: '/procure/delivery',
          name: 'procuredelivery',
          meta: { title: '每日食材配送', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/每日食材配送表.cpt&op=write' }
        },
        {
          path: '/procure/detail',
          name: 'procuredetail',
          meta: { title: '每日配送明细', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/每日配送明细.cpt&op=write' }
        },
        {
          path: '/procure/money',
          name: 'procuremoney',
          meta: { title: '配送总金额', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/配送总金额.cpt&op=write' }
        },
        {
          path: '/procure/weekmenu',
          name: 'procureweekmenu',
          meta: { title: '周菜单', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/食堂菜谱.cpt&op=write' }
        },
        {
          path: '/package/warehouse',
          name: 'packagewarehouse',
          meta: { title: '套餐菜品库', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/套餐菜品库.cpt&op=view' }
        },
        {
          path: '/procure/quotation',
          name: 'procurequotation',
          meta: { title: '供应商报价', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/供应商报价.cpt&op=write' }
        },
        {
          path: '/procure/fooddata',
          name: 'procurefooddata',
          meta: { title: '食材主数据', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/食材主数据.cpt&op=write' }
        },
        {
          path: '/costing/canteenfilling',
          name: 'canteenfilling',
          meta: { title: '食堂填报明细', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂成本核算/食堂填报明细.cpt&op=write' }
        },
        {
          path: '/costing/inventorywrite',
          name: 'costinginventorywrite',
          meta: { title: '库存填报', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/仓库管理/食堂库存填报.cpt&op=write' }
        },
        {
          path: '/costing/balance',
          name: 'costingbalance',
          meta: { title: '结存填报', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/仓库管理/米油煤气结存填报.cpt&op=write' }
        },
        {
          path: '/costing/inventoryledger',
          name: 'costinginventoryledger',
          meta: { title: '库存台账', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/仓库管理/食堂库存台账.cpt&op=write' }
        },
        {
          path: '/costing/hydropower',
          name: 'costinghydropower',
          meta: { title: '水电蒸汽明细', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂成本核算/水电蒸汽每月明细表.cpt&op=write' }
        },
        {
          path: '/costing/revenueexpenditure',
          name: 'revenueexpenditure',
          meta: { title: '食堂收支明细', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂成本核算/食堂收支明细表.cpt&op=write' }
        },
        {
          path: '/costing/reportnew',
          name: 'costingreportnew',
          meta: { title: '食堂汇总月报新', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂成本核算/食堂汇总月报新.cpt&op=write' }
        },
        {
          path: '/costing/costs',
          name: 'costs',
          meta: { title: '经营成本分析', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂成本核算/食堂每元补贴.cpt&op=write' }
        },
        {
          path: '/visitor/list',
          name: 'visitorlist',
          meta: { title: '访客来访信息查询', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/访客/访客来访信息查询.cpt&op=view' }
        },
        {
          path: '/fooddepot/summary',
          name: 'fooddepotsummary',
          meta: { title: '库存台账', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/库存录入/食堂库存台账.cpt&ref_t=design&op=view' }
        },
        {
          path: '/fooddepot/detail',
          name: 'fooddepotdetail',
          meta: { title: '出入库明细', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/库存录入/食堂出入库明细表.cpt&ref_t=design&op=view' }
        },
        {
          path: '/fooddepot/Input',
          name: 'fooddepotInput',
          meta: { title: '库存填报', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/库存录入/食堂入库填报.cpt&ref_t=design&op=write' }
        },
        {
          path: '/fooddepot/balance',
          name: 'balance',
          component: () => import('../views/fooddepot/balance.vue'),
          meta: { title: '食堂库存结存' }
        },
        {
          path: '/question/count/person',
          name: 'questioncountperson',
          meta: { title: '满意度部门统计', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/满意度/满意度参与人员明细.cpt&ref_t=design&op=view' }
        },
        {
          path: '/question/count/submmary',
          name: 'questioncountsubmmary',
          meta: { title: '满意度分析统计', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/满意度/食堂反馈分析统计.cpt&ref_t=design&op=view' }
        },
        {
          path: '/question/count/detail',
          name: 'questioncountdetail',
          meta: { title: '满意度分析明细', iframeUrl:'http://fanruan.textaihua.com:38080/WebReport/decision/view/report?viewlet=th/食堂分析/满意度/食堂问题解决明细.cpt&ref_t=design&op=view' }
        },
        {
          path: '/room/modify/list',
          name: 'roommodifylist',
          meta: { title: '房间修改记录', iframeUrl:'http://fanruan.textaihua.com:38080//WebReport/decision/view/report?viewlet=th/水电费/房间类型修改记录表.cpt&ref_t=design&op=view' }
        }
        ,
        {
          path: '/food/question',
          name: 'foodquestion',
          meta: { title: '调查菜品维护', iframeUrl:'http://fanruan.textaihua.com:38080//WebReport/decision/view/report?viewlet=th/菜品调查/PC端/问卷菜品维护.cpt&ref_t=design&op=write' }
        }
        ,
        {
          path: '/food/describe',
          name: 'fooddescribe',
          meta: { title: '调查数据维护', iframeUrl:'http://fanruan.textaihua.com:38080//WebReport/decision/view/report?viewlet=th/菜品调查/PC端//问卷数据维护.cpt&ref_t=design&op=write' }
        },
        ,
        {
          path: '/food/report/summary',
          name: 'foodreportsummary',
          meta: { title: '调查数据汇总', iframeUrl:'http://fanruan.textaihua.com:38080//WebReport/decision/view/report?viewlet=th/菜品调查/PC端/统计报表/参与情况汇总.cpt&ref_t=design&op=view' }
        }
        ,
        {
          path: '/food/report/detail',
          name: 'foodreportdetail',
          meta: { title: '调查数据明细', iframeUrl:'http://fanruan.textaihua.com:38080//WebReport/decision/view/report?viewlet=th/菜品调查/PC端/统计报表/参与情况明细.cpt&ref_t=design&op=view' }
        }
        ,
        {
          path: '/food/report/sort',
          name: 'foodreportsort',
          meta: { title: '菜品调查排名', iframeUrl:'http://fanruan.textaihua.com:38080//WebReport/decision/view/report?viewlet=th/菜品调查/PC端/统计报表/菜品调查排名.cpt&ref_t=design&op=view' }
        },
        {
          path: '/month/submit',
          name: 'monthsubmit',
          meta: { title: '月度数据录入情况', iframeUrl:'http://fanruan.textaihua.com:38080//WebReport/decision/view/report?viewlet=th/食堂月报/月度数据录入情况.cpt&ref_t=design&op=view' }
        }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/scale',
    name: 'scale',
    component: () => import('../views/scale/index.vue'),
    meta: { title: '电子称' }
  },
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  const token = getToken()

  if (!token && to.path !== '/login') {
    next('/login')
  } else {
    // if(to.path =='/dataRecon/summary'){

    //   // to.meta.iframeUrl = 'www.baidu.com';
    // }
    next()
  }
})


export default router
